// CONTACT

.contact {
	.noform{
		.block-content{
			p{
				font-size: 1.4rem;
			}
		}
	}
}