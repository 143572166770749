//------------------------------------
// #COMPONENTS
//------------------------------------

@import "cookies";
@import "buttons";
@import "forms";

/*

// Mediaqueries
// w480
@media only screen and ( min-width: 30rem ) {
  @import "cookies-30-480";
}
// w768
@media only screen and ( min-width: 48rem ) {
  @import "cookies-48-768";
}
 */
// w640
@media only screen and ( min-width: 40rem ) {
  @import "forms-40-640";
}
// w1008
/* @media only screen and ( min-width: 63rem ) { */
@media only screen and ( min-width: 1008px ) {
  @import "cookies-63-1008";
}

