// CAREERS

.careers { 
	.lame{

		&.section__career-intro {
			background-color: #007EDB;
			color: #ffffff;
			font-weight: 300;
			a{
				text-decoration: underline;
			}
		}

		&.nooffer{
			/*display: none;*/
			
			p{
				text-align: center;
				text-transform: uppercase;
				font-weight: 300;
			}
		}

		&.jobprez .lame__content{
			min-height: 0;
			border-top: 1px solid #d4d4d4;
			border-bottom: 1px solid #d4d4d4;
		}

		&:nth-child(2) .lame__content:first-child{
			border-top: 0;
		}

		&.jobprez .block-content {
			margin: 0;

			p{
				margin: 12px 0;
			}
			.buttoncta{
				margin-top: 10px;
				margin-bottom: 10px;
			}
		}
	
		.jobpubdate{
			font-size: 1.2rem;
			color:#8c8c8c;
		}
		.jobtype{
			font-size: 2.4rem;
			line-height: 1;
		}
		.jobtitle{
			font-size: 2.2rem;
			font-weight: 300;
			line-height: 1.1;
		}
		.joblocation{
			font-size: 1.4rem;
		}
	}

	.block-content a {
		text-decoration: underline;
	}

	.jobcard{
		display: none;
		padding: 20px 0 40px;

		.block-content{
			padding-top: 30px;
			z-index: 0;
		}

		.closecard{
			position: absolute;
			top: 0;
			z-index: 10;
			right: 6.25%;
			width: 20px;
			height: 20px;

			.icon-close{
				width: 20px;
				height: 20px;
			}
		}
		h3{
			font-weight: 400;
			font-size: 1.8rem;
		    margin: 2rem 0;
		    line-height: 1.2;
		}

		.job-titles h3 {
			font-weight: 600;
			font-size: 1.6rem;
		}

		.cardblock{
			border-bottom: 1px solid #b1b1b1;
			padding-bottom: 2rem;
			margin-bottom: 2rem;

			h4{
				margin-bottom: 0;
			}

			p, ul {
				margin: 0 0 1rem;
			}
			div.dataresult{
				margin-top: 1rem;
			}
			li{
				display: list-item;
				list-style-type: disc;
				list-style-position: inside;
			}
		}
	}
}