// SUCCESS STORY

.success-story {
	.section__intro{
		max-height: none;
	}	
  .section__intro .lame__content {
    .block-content {
        h1,h2 {
        	font-size: 2rem !important;
          .small-print{
          }
        }
        h3{
        }
        ul {
        	p{
        		font-size: 1.4rem !important;
        	}
        	&.mq-select{
        		margin-top: 1.5em;
        		margin-bottom: 2em;
        		letter-spacing: 4em;
        		li{
        			padding-bottom: 1em;
        			font-size: 1.4rem;
        			letter-spacing: normal;
        		}
        	}
        }
        .success-story-carousel{
            margin-left: 32.9%;
            li:before{
                background-color: #f0f0f0;
            }

            .timeline-peugeot{
                width: 80%;
                margin: auto;
                >picture{
                    width:80%;
                }
            }

            &.nocarousel{
                justify-content: center;
            }
        }
        .world-story-carousel{
            margin-left: 32.9%;

            .timeline-world{
                width: 80%;
            }

            &.nocarousel{
                justify-content: center;
            }
        }
        .timeline-detail {
            position: absolute;
            /* top: -20px;
            bottom: auto;
            height: auto;
            left: auto;
            right: 35%;
            width: auto; */
            top: -1000px;
            bottom: -1000px;
            height: auto;
            left: -1000px;
            right: -1000px;
            width: auto;
            margin: auto;
            background-color: rgba(0, 0, 0, 0.3);

            .timeline-detail-content {
                /* top: 0px; */
                height: auto;
                top: 49%;
                transform: translateY(-50%);
                right: 35%;
                left: auto;
                h5{
                    color:$mainColor;
                    font-size: 2rem;
                    font-weight: 400;
                    margin-bottom: 15px;
                }
                picture img{
                    margin-bottom: 20px;
                    width: auto;
                }
                p{
                    font-size: 1.3rem !important;
                    margin-bottom: 20px;
                    font-weight: 400 !important;
                }
                a.simplecta{
                    font-size: 1.4rem;
                }
                &:after{
                    content:'';
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    background-color: #fff;
                    top: 50%;
                    transform: rotate(45deg) translateY(-50%);
                    left:-12px;
                }
            }
        }
    }
    .bx-wrapper {

        .bx-controls-direction a {
          top: 84px;
          left: 20px;
        }

        .bx-controls-direction a.bx-next {
          right: 20px;
          left: auto;
        }

    }
  }

  .section__120ans-detail-2 {
    img.schedoni{
      display: block;
    width: 120px;
    margin: 0 0 30px;
    }
  }
  
}
