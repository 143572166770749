// TERMS

.accordionContainer{
	padding: 20px 0;

	.block-content{
		h1{
			margin-bottom: 25px;
		}
	}

	section.block-content{
		border-top: 1px solid $gray;
		padding: 11px 5.25% 0 6.25%;

		h2{
			font-weight: 400;
			position: relative;
			padding: 5px 15px 5px 0;
    		margin-bottom: 11px;
    		font-size: 1.3rem;

			span{
				color:$azurColor;
			}
			
			&:after{
			    content: '';
			    display: block;
			    position: absolute;
			    right: 4px;
			    top: 9px;
			    width: 6px;
			    height: 6px;
			    border-right: 2px solid $azurColor;
			    border-bottom: 2px solid $azurColor;
			    transform: rotate(45deg);
				transition:0.25s ease-in-out;
			}
		}
		.folding{
			padding-right: 25px;
		}
		p{
			font-size: 1.3rem;
			margin: 0;
			padding-bottom: 16px;
		}

		&.open{

			h2{
				span{
					color:$mainColor;
				}

				&:after{
			    	border-right: 2px solid $mainColor;
			    	border-bottom: 2px solid $mainColor;
			    	transform: rotate(-135deg);
				}
			}
		}
		
	}
}