// LOCATIONS

.locations {
	section.block-content {
		.blocklegend{
			.maplegend{
				&.lg5{
					ul{
						column-count: 4;
					}
				}
			}
		}
	}
}