//-------------------------------------------------
// body-63-1008


.container {
	padding-top: $headerHeightTablet;
}


.lame{
	max-height: 571px;
}
.lame .lame__content{
	min-height: 35.1vw;
}
.section__intro .lame__content{
	min-height:35.1vw !important;
}
.lame-var1 .lame__content{
	min-height: 28.1vw;
	max-height: 457px;
}
.block-content h1{
	margin: 0 auto 16px;
}
.block-content h2{
	margin: 0 auto 16px;
}

.section__intro .block-content h1 {
    font-size: 3.2rem !important;
}
.section__intro .block-content h2 {
    font-size: 3.2rem !important;
}
.block-content .buttoncta{
	margin: 24px auto 0;
}
.section__intro .block-content p{
	font-size: 2rem !important;
	font-weight: 300 !important;
}

.lame.section__career-intro .lame__content,
.lame.nooffer .lame__content {
    min-height: 11.1vw !important;
}