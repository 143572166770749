// BODY


body {
  height: 100%;
}

body.noscroll {
	overflow: hidden;
}

em{
	font-style: normal;
	font-weight: bolder;
}
.note{
	font-size: 1.2rem;
	font-weight: 300;
}
.container {
	position: relative;
	width: 100%;
	min-height: 100%;
	padding-top: 60px;
	max-width: 1600px;
	margin: auto;
}

.dark-theme{
	color: $secondaryColor;
}

.lame{
	position: relative;
	overflow: hidden;
    display: flex;
    flex-direction: column;
}
.lame__content{
	min-height:78.125vw;
    flex-grow: 1;
    flex-basis: auto;
    display: flex;
    /* flex-direction: column;
    justify-content: center; */
    align-items: center;
    overflow: hidden;
}
.section__intro .lame__content{
	min-height:78.125vw !important;
}
.lame-var1 .lame__content{
	min-height:57.1875vw;
	position: relative;
}
.lame.section__career-intro .lame__content,
.lame.nooffer .lame__content {
    min-height: 11.1vw !important;
}
.bg-lame img{
    position: absolute;
    left: -1000px;
    right: -1000px;
	width: 100%;
	top:50%;
	transform:translateY(-50%);
    margin: auto;
    max-width: none;
}
.block-content{
	position: relative;
	z-index: 1;
	margin: auto;
	padding: 0 6.25%;

}
.block-content h1{
	text-transform: uppercase;
	font-weight: 300;
	font-size: 2rem;
	margin: 0 auto 16px;
}
.block-content h2{
	text-transform: uppercase;
	font-weight: 300;
	font-size: 2rem;
	margin: 0 auto 16px;
}
.block-content h3{
	text-transform: uppercase;
	font-weight: 300;
	font-size: 1.3rem;
	margin: 0 auto 16px;
}
.section__intro .block-content h1{
	font-size: 2rem !important;
	font-weight: 300 !important;
}
.section__intro .block-content h2{
	font-size: 2rem !important;
	font-weight: 300 !important;
}
.block-content p{
	margin: 16px 0;
}
.block-content .buttoncta{
	margin: 24px auto 0;
}
.bx-wrapper .bx-pager{
	font-size: 1rem;
}
.bx-wrapper .bx-pager, .bx-wrapper .bx-controls-auto{
	bottom: 20px;
}
.spanbreak{
	display: block;
}