//------------------------------------
// footer-63-1008

footer{
	
	font-size: 1.2rem; 
	min-height: 48px;
	line-height: 48px;
	padding-bottom: 0;
	
	.footer__links{
		padding-left: 18px;
	}
	.footer__links li{
		display: inline-block;
		text-align: left;
	}
	.footer__links li.copyright{
    min-height: 0;
    line-height: normal;
    margin-bottom: 0;
    border-bottom: 0;
    padding-right: 10px;
	}
	.footer__links li.copyright img{
		width: 22px;
		height: 22px;
	}
	.footer__links li a{
		line-height: normal;
	}
	.footer__links li a:first-child{
    border-left: 1px solid $darkBdColor;
	}
}