// HELPERS

.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.ir {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

.aligncenter{
	text-align: center;
}

.alignright{
	text-align: right;
}