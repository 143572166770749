// WEBSITES

.websites {

	.section__intro .lame__content .block-content{
		padding-left: 26.6%;
    	padding-right: 26.6%;
	}

	.tile {

		.block-content h1, .block-content h2{
			font-size: 2rem;
		}
		.block-content p{
			font-size: 1.6rem;
		}
		.block-content a{
			font-size: 1.6rem;
		}
	}
}