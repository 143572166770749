.cookies {
	width: 100%;
	background-color: $azurColor;
	color:#fff;
	text-align: left;
	position: fixed;
	top: 0px;
	z-index: 15;
	padding: 10px 45px 12px 15px;
	min-height: 80px;
	font-size: 1.2rem;
	display: none;

	br{
		display: none;
	}
	.cookies__close {
		display: block;
		background-color: #fff;
		height: 20px;
		position: absolute;
		right: 10px;
		top: 12px;
		svg{
			width: 20px;
			height: 20px;
		}
	}
}