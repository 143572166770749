//------------------------------------
// #LAYOUT
//------------------------------------

html{
	height: 100%;
}

@import "header";
@import "body";
@import "footer";



// Mediaqueries
// w480
@media only screen and ( min-width: 30rem ) {
}
// w640
/*@media only screen and ( min-width: 40rem ) {*/
@media only screen and ( min-width: 640px ) {
  @import "header-40-640";
  @import "body-40-640";
  @import "footer-40-640";
}
// w768
@media only screen and ( min-width: 48rem ) {
}
// w1008
/* @media only screen and ( min-width: 63rem ) { */
  @media only screen and ( min-width: 1008px ) {
  @import "header-63-1008";
  @import "body-63-1008";
  @import "footer-63-1008";
}
// w1280
/* @media only screen and ( min-width: 80rem ) { */
@media only screen and ( min-width: 1280px ) {
  @import "header-80-1280";
  @import "body-80-1280";
}
// w1600
/* @media only screen and ( min-width: 100rem ) { */
@media only screen and ( min-width: 1600px ) {
  @import "body-100-1600";
}