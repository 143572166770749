//-------------------------------------------------
// body-100-1600

.lame .lame__content{
	min-height: 0;
	height: 571px;
}


.lame-var1 .lame__content{
	min-height: 0;
	height: 457px;
}