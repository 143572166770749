// Footer
footer{
	background-color: $peugeotBgColor;
	color:$secondaryColor;
	font-size: 1.1rem;
	/* position: absolute;
	bottom: 0;
	left: 0;
	right: 0; */
	min-height: 80px;
	padding-bottom: 10px;
	
	.footer__links{
		
	}
	.footer__links li{
		display: block;
		text-align: center;
	}
	.footer__links li.copyright{
		border-bottom: 1px solid $darkBdColor;
		min-height: 46px;
		line-height: 46px;
		margin-bottom: 10px;
	}
	.footer__links li.copyright img{
		width: 24px;
		height: 24px;
		margin-right: 10px;
	}
	.footer__links li a{
		display: inline-block;
		border-left: 1px solid $darkBdColor;
		padding: 0 8px;
		margin: 3px 0;
	}
	.footer__links li a:first-child{
		border-left: 0;
	}
}