// Header
header {
	
	/* position: relative; */
	position: fixed;
	top: 0;
	z-index: 20;
	width: 100%;
	background-color: $peugeotBgColor;
	color:$secondaryColor;
	height: $headerHeightMobile;
	border-bottom: 1px solid $darkBdColor;


	.burger-menu{
	    display: block;
	    position: absolute;
	    left: 0px;
	    height: 59px;
	    width: $headerHeightMobile;
	    z-index: 1;
	    transition:0.5s ease-in-out;
	    cursor: pointer;
	}
	.logopeugeot{
		display: block;
		position: absolute;
		width: 74px;
		height: auto;
		left: 0;
		right: 0;
		margin: auto;
		top: 50%;
		transform:translateY(-50%); 
	}
	.logopeugeot img{
		width: 100%;
		height: auto;
	}
	.mobile.contact{
		display: block;
		width: 40px;
		height: 40px;
		position: absolute;
		top: 50%;
		transform:translateY(-50%); 
		right: 16px;
	}
	.icon{
		width: 40px;
		height: 40px;
		fill:$menuColor;
	}
	.mobile .icon-contact{
		fill:$secondaryColor;
	}
	.main-nav{
		position: absolute;
		z-index: 1;
		width: 262px;
		background-color: $darkBgColor;
		top: $headerHeightMobile;
		left: -262px;
		transition: left 0.25s ease-in-out;
		height: calc(100vh - #{$headerHeightMobile});
		overflow: auto;

		.main-nav__rub{
			display: block;
			width: 100%;
			position: relative;
			padding-left: 58px;
			text-transform: uppercase;
		}
		.main-nav__rub, .main-nav__alt, .main-nav__lang{
			min-height: 50px;
			border-bottom: 1px solid $darkBdColor;
		}
		.icon{
			position: absolute;
			left: 9px;
			top: 50%;
			transform:translateY(-50%); 
		}
		.main-nav__rub span{
			line-height: 50px;
		}
		.main-nav__lang, .main-nav__socials{
		    display: table;
		    height: 50px;
		    width: 100%;
		    table-layout: fixed;
		    border-collapse: collapse;
			font-size: 1.8rem;
			font-family: 'peugeot';
			font-weight: 300;
			color:$menuColor;
		}
		.main-nav__lang a, .main-nav__socials a{
			position: relative;
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			border-right: 1px solid $darkBdColor;
		}
		.main-nav__lang a.activ{
			color:$azurColor;
		}
		.main-nav__lang a:last-child, .main-nav__socials a:last-child{
			border-right: 0;
		}
		.main-nav__lang span{
			display: none;
		}
		.main-nav__socials a .icon{
			left: 0;
			right: 0;
			margin: auto;
		}
	}
	
	&.withCookieBanner{
		top:80px;
	}

	.burger-menu {
		span{
		    display: block;
		    position: absolute;
		    height: 2px;
		    width: 27px;
		    background: #fff;
		    @include borderradius(1px);
		    opacity: 1;
		    left: 0;
		    right: 0;
		    margin: auto;
		    transform:rotate(0deg);
	    	transition:0.25s ease-in-out;
		}
		span:nth-child(1) {
			top: 22px;
		}

		span:nth-child(2),span:nth-child(3) {
			top: 28px;
		}

		span:nth-child(4) {
			top: 34px;
		}
	}

	.burger-menu.open {
		background-color: #ececec;

		span {
			background-color:$peugeotBgColor;
		}

		span:nth-child(1) {
			top: 22px;
			width: 0%;
			left: 50%;
		}

		span:nth-child(2) {
			transform:rotate(45deg);
		}

		span:nth-child(3) {
			transform:rotate(-45deg);
		}

		span:nth-child(4) {
			top: 22px;
			width: 0%;
			left: 50%;
		}
	}
}

.container{
	&:after{
		content: '';
		transition: background-color 0.25s ease-in-out;
	}
	&.open-mobile-menu{
	
		overflow: hidden;
		.main-nav{
			left: 0px;
			position: fixed;
			overflow-y: scroll;
			top: $headerHeightMobile;
			bottom: 0px;
		}
		header.withCookieBanner .main-nav{
			top: calc(80px + #{$headerHeightMobile});
		}
		.mobile.contact{
			display: none;
		}
		&:after{
			content: '';
		    position: absolute;
		    z-index: 10;
		    background-color: rgba(0,0,0,0.5);
		    width: 100%;
		    height: calc(100% - #{$headerHeightMobile});
		    top: $headerHeightMobile;
		}
	}
}




.success-story .rub-success-story,
.values .rub-values,
.websites .rub-websites,
.locations .rub-locations,
.range .rub-range,
.press .rub-press,
.careers .rub-careers,
.contact .rub-contact
{
	background-color: $azurColor;

	.icon{
		fill:$secondaryColor;
	}
}
