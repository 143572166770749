// LOCATIONS

.locations {
	section.block-content {
		background-color: #f0f0f0;
		padding-top: 1px;
		h2{
			font-size: 1.4rem;
			margin-top: 16px;
		}
		.blocklegend{
		    display: flex;
		    flex-wrap: wrap;
		    justify-content: space-between;
		    width: 100%;
			.maplegend{
				margin: 10px 0;
				width: 45%;
				/* flex-grow:1; */
				h3{
					font-weight: 400;
					font-size: 1.2rem;
					text-transform: uppercase;
					position: relative;
					padding-left: 16px;
					padding-bottom: 10px;
					border-bottom-width: 1px;
					border-bottom-style: solid;

					&:before{
						position:absolute;
						content: '';
						width: 10px;
						height: 10px;
						background-color: #000;
						left: 0;
						top: 2px;
					}
				}
				a{
					text-decoration: underline;
				}
				ul{
					margin: 10px 0;
					li{
						font-size: 1.1rem;
						margin: 0 0 10px;
					    position: relative;
					    padding: 2px 5px 2px 40px;
						&:before{
						    position: absolute;
						    content: '';
						    left: 0;
						    top: 50%;
						    transform: translateY(-50%);
						    width: 26px;
						    height: 16px;
						    background-image: url(../images/signs/flags-sprite.png);
						    background-repeat: no-repeat;
						    background-position: inherit;
						}
					}
				}
				&.lg1{
					border-bottom-color: #b73f9e;
    				margin-right: 3%;
    				order: 1;
					h3{
						color: #b73f9e;
						&:before{
							background-color: #b73f9e;
						}
					}
				}
				&.lg2{
					border-bottom-color: #007edb;
    				margin-right: 3%;
    				order: 3;
					h3{
						color: #007edb;
						&:before{
							background-color: #007edb;
						}
					}
				}
				&.lg3{
					border-bottom-color: #152946;
    				margin-left: 3%;
    				order: 2;
					h3{
						color: #152946;
						&:before{
							background-color: #152946;
							border-radius: 5px;
						}
					}
				}
				&.lg4{
					border-bottom-color: #007491;
    				margin-left: 3%;
    				order: 4;
					h3{
						color: #007491;
						&:before{
							background-color: #007491;
						}
					}
				}
				&.lg5{
					border-bottom-color: #47b5ee;
				    flex-grow: 2;
				    width: auto;
				    order: 5;
					h3{
						color: #47b5ee;
						&:before{
							background-color: #47b5ee;
						}
					}
					ul{
						column-count: 2;
						li{
							display: inline-block;
							width: 100%;
						}
					}
				}
			}
		}
	}
}