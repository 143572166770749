// LINKS

a {
	color: inherit;
	text-decoration: none;

  &:hover {
    /* text-decoration: underline; */
  }
}

.buttoncta{
	height: 40px;
	line-height: 40px;
	border-bottom-width: 3px;
	border-bottom-style: solid;
	border-bottom-color: $azurdarkColor;
	background-color: $azurColor;
	display: inline-block;
	padding: 0 30px;
	text-align: center;
	text-transform: uppercase;
	font-size: 1.2rem;
	color: $secondaryColor;
	transition:0.25s ease-in-out;

	&:hover{
		background-color: $azurdarkColor;
	}

	&.grayversion{
	color: $textDarkColor;
	background-color: $lightgray;
	border-bottom-color: $gray;

		&:hover{
			background-color: $gray;
		}
	}

	&.darkblueversion{
	background-color: $darkBgColor;
	border-bottom-color: $peugeotBgColor;

		&:hover{
			background-color: $peugeotBgColor;
		}
	}

}

.simplecta{
	font-weight: 300;
	position: relative;
	color: $azurColor;
	/* white-space: nowrap; */
	transition:0.25s ease-in-out;

	&:after{
	    content: '';
	    width: 6px;
	    height: 6px;
	    border-right: 1px solid $azurColor;
	    border-bottom: 1px solid $azurColor;
	    transform: rotate(-45deg);
	    position: relative;
	    display: inline-block;
	    margin-left: 5px;
		transition:0.25s ease-in-out;
	}

	&:hover{
		color: $linkoverColor;
	}

	&:hover:after{
	    border-right: 1px solid $linkoverColor;
	    border-bottom: 1px solid $linkoverColor;
	}

	&.simplecta-alt{
		&:after{
			width: 16px;
			height: 16px;
	    	border-right: 0;
	    	border-bottom: 0;
	    	transform: rotate(0deg);
	    	background: url(../images/signs/alt-simplecta.png) no-repeat;
		    top: 4px;
		    margin-left: 9px;
		}
	}
}

.dark-theme .simplecta{
	color:#fff;

	&:hover{
		color: $linkoverColor;
		color: #007edb;
	}

	&:after{
	    border-right: 1px solid $secondaryColor;
	    border-bottom: 1px solid $secondaryColor;

	}

	&:hover:after{
	    border-right: 1px solid $linkoverColor;
	    border-bottom: 1px solid $linkoverColor;
	    border-right: 1px solid #007edb;
	    border-bottom: 1px solid #007edb;
	}

	&-alt{
		&:hover{
			color:#007edb;
			&:after{
				border-right: 0;
				border-bottom: 0;
			}
		}
		&:after{
			border-right: 0;
			border-bottom: 0;
		}
	}
}

.playcta{
    margin-top: 4rem;
    width: 6rem;
    height: 6rem;
    background: #fff;
    display: inline-block;
    position: relative;
    transition: color , background;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    backface-visibility: hidden;
    will-change: color , background;
    color: #007edb;
    &:hover{
	    background: #007edb;
	    color: #fff;
    }
    svg {
	    position: absolute;
	    left: 50%;
	    top: 50%;
	    margin-top: -0.5em;
	    margin-left: -0.5em;
	    line-height: 100%;
	    width: 1em;
	    height: 1em;
	    font-size: 20px;
	    font-size: 2rem;
	    fill: currentColor;
	}
}

