// VALUES

.values {

	.bg-lame img{
		top:0;
		transform:translateY(0%);
	}

	.section__qualite{
		background-color: #09263c;
	}

	.section__securite{
		background-color: #1c1c1c;
	}

	.block-content{
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.block-content h1{
		font-size: 2rem;
		font-weight: 400;
	}

	.block-content h2{
		font-size: 2rem;
		font-weight: 400;
	}

	/* .section__intro .block-content h1{
		font-size: 2rem;
		font-weight: 300;
	} */

	.block-content p{
		font-size: 1.4rem;
	}
}