// Form fields

input, textarea, select{
	outline: none;
    font-family: inherit;
    font-size: 1.4rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-border-radius:0;
    border-radius:0;
}
select{
    font-size: 1.6rem; 
}
input[type="text"], textarea, select{
	border:1px solid #b3b3b3;
}
input[type="text"]{
	height: 40px;
	line-height: 40px;
}
.label{
	color: $azurColor;
}
.skinradio {
	display: inline-block;
	width: 15px;
	height: 15px;
	background-image: url(../images/signs/skinradio.png);
	background-repeat: no-repeat;
	vertical-align: text-top;
	margin-right: 7px;

	&.checked{
		background-position: 0 -15px;
	}
	
	input[type="radio"]{
		opacity: 0;
		width: 15px;
		height: 15px;
		display: block;
		outline: none;
		border: 0;
		padding: 0;
		margin: 0;
	}
}
.skincheck {
	display: inline-block;
	width: 15px;
	height: 15px;
	background-image: url(../images/signs/skincheck.png);
	background-repeat: no-repeat;
	vertical-align: text-top;
	margin-right: 7px;

	&.checked{
		background-position: 0 -15px;
	}
	
	input[type="checkbox"]{
		opacity: 0;
		width: 15px;
		height: 15px;
		display: block;
		outline: none;
		border: 0;
		padding: 0;
		margin: 0;
	}
}

input[type="submit"]{
	height: 50px;
	line-height: 50px;
	border-style: solid;
	border-width: 0;
	border-bottom-width: 3px;
	border-bottom-style: solid;
	display: inline-block;
	padding: 0 30px;
	text-align: center;
	text-transform: uppercase;
	font-size: 1.2rem;
	border-bottom-color:$azurdarkColor;
	background-color: $azurColor;
	color:$secondaryColor;
	cursor: pointer;
	transition:0.25s ease-in-out;

	&:hover{
		background-color: $azurdarkColor;
	}
}

.skinfileinput{
	height: 40px;
	position: relative;
	overflow: hidden;
	font-size: 1.2rem;
}
.skinfileinput:after{
	content:attr(data-ctatext);
	position: absolute;
	top: 0;
	left: 0;
	width: 53%;
	z-index: 0;
	height: 40px;
	line-height: 40px;
	text-align: center;
	text-transform: uppercase;
	background-color: #b4b4b4;
	color:$secondaryColor;
}
.skinfileinput input[type="file"]{
	height: 40px;
	opacity: 0;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
}
.skinfileinput .filepath{
	position: absolute;
	z-index: 0;
	top: 50%;
	transform:translateY(-50%);
	left: 55%;
	color: #aaa;
	line-height: 1;
}

span.error, span.valid{
	display: inline-block;
	position: relative;
	margin: 0px;
	width: 100%;
}

span.error:after, span.valid:after{
    position: absolute;
    content: "";
    z-index: 1;
    width: 20px;
    height: 20px;
    right: 10px;
    top: 10px;
    /* transform:translateY(-50%); */
}

.skinradio span.error:after, .skinradio span.valid:after{
	content: none;
}

span.error:after{
    background: url(../images/signs/error.png) no-repeat;
}

span.valid:after{
    background: url(../images/signs/valid.png) no-repeat;
}

span.error input[type="text"], span.error textarea{
	border:1px solid #891421;
}

p.error{
	color:#891422;
}


.selectric-wrapper{
	text-align: left;
	&:first-child{
		margin-bottom: 8px;
	}
	.selectric {
		border-color:#c4c4c4;
	  
	  .button {
	    &:after {
	      top: 0;
	      right: 0;
	      margin: auto;
	      width: 7px;
	      height: 7px;
	      border: 2px solid $azurColor;
	      border-top-width: 0;
	      border-left-width: 0;
	      transform: rotate(45deg);
	      transform-origin: 5px;
	    }
	  }
	}
  	.selectric-items li {
  		position: relative;
  		padding: 10px 8%;
  		&:after{
			content:"";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			width: 84%;
			height: 0;
			border-top: 1px solid #b3b3b3;
			margin: auto;
  		}
	  	&:hover{
	  		background-color:$azurColor;
	  		color:#fff;
	  	}
	  	&:hover:after{
	  		content:none;
	  	}
  	}
  	&.selectric-open{
  		.selectric{
  			border-bottom-color: transparent;
  		}
  		.selectric-items{
  			border-top-color: transparent;
  		}
  	}
  	&.selectric-open.selectric-above{
  		.selectric{
  			border-bottom-color: #c4c4c4;
  			border-top-color: transparent;
  		}
  		.selectric-items{
  			border-top-color: #c4c4c4;
  			border-bottom-color: transparent;
  			li:after{
  				top:auto;
  				bottom:0;
  			}
  		}
  	}
}