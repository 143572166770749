// FONTS





// FONT FACE

@font-face {
    font-family: 'peugeot';
    src: url('../fonts/peugeot-webfont.eot');
    src: url('../fonts/peugeot-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/peugeot-webfont.woff2') format('woff2'),
         url('../fonts/peugeot-webfont.woff') format('woff'),
         url('../fonts/peugeot-webfont.ttf') format('truetype'),
         url('../fonts/peugeot-webfont.svg#peugeotregular') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'peugeot';
    src: url('../fonts/peugeot-bold-webfont.eot');
    src: url('../fonts/peugeot-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/peugeot-bold-webfont.woff2') format('woff2'),
         url('../fonts/peugeot-bold-webfont.woff') format('woff'),
         url('../fonts/peugeot-bold-webfont.ttf') format('truetype'),
         url('../fonts/peugeot-bold-webfont.svg#peugeotbold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'peugeot';
    src: url('../fonts/peugeot-light-webfont.eot');
    src: url('../fonts/peugeot-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/peugeot-light-webfont.woff2') format('woff2'),
         url('../fonts/peugeot-light-webfont.woff') format('woff'),
         url('../fonts/peugeot-light-webfont.ttf') format('truetype'),
         url('../fonts/peugeot-light-webfont.svg#peugeotlight') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'peugeotitalic';
    src: url('../fonts/peugeot-italic-webfont.eot');
    src: url('../fonts/peugeot-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/peugeot-italic-webfont.woff2') format('woff2'),
         url('../fonts/peugeot-italic-webfont.woff') format('woff'),
         url('../fonts/peugeot-italic-webfont.ttf') format('truetype'),
         url('../fonts/peugeot-italic-webfont.svg#peugeotitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'peugeotbold_italic';
    src: url('../fonts/peugeot-bolditalic-webfont.eot');
    src: url('../fonts/peugeot-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/peugeot-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/peugeot-bolditalic-webfont.woff') format('woff'),
         url('../fonts/peugeot-bolditalic-webfont.ttf') format('truetype'),
         url('../fonts/peugeot-bolditalic-webfont.svg#peugeotbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'peugeotlight_italic';
    src: url('../fonts/peugeot-lightitalic-webfont.eot');
    src: url('../fonts/peugeot-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/peugeot-lightitalic-webfont.woff2') format('woff2'),
         url('../fonts/peugeot-lightitalic-webfont.woff') format('woff'),
         url('../fonts/peugeot-lightitalic-webfont.ttf') format('truetype'),
         url('../fonts/peugeot-lightitalic-webfont.svg#peugeotlight_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

// FONT FAMILIES
body {
  font-family: 'peugeot', Arial, Helvetica, sans-serif;
}

// FONT SIZES
// based on https://blog.goetter.fr/2016/07/05/lastuce-du-font-size-62-5-oui-non-ou-ca-depend/
html {
  font-size: .625em; /* fallback IE8+ */
  font-size: calc(1em * .625); /* IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
}
body {
  font-size: 1.4em; /* base font-size is equivalent "14px" */
}

// FONT COLORS

body {
	color: $mainColor;
}
