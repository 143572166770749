// CONTACT

.contact {
	form{
		max-width: 1000px;

		.group{
			width: auto;
		}

		h3{
			margin-bottom: 30px;
		}

		.group{
			padding-top: 30px;
		}


		.group1{
			border-bottom: 1px solid #dadada;
			padding-top: 0;

			.radiowrapper{
				display: inline-block;
				margin-top: 0;
				margin-bottom: 14px;

				.skinradio{
					margin-right: 7px;
				}

				label{
					margin-right: 15px;
				}
			}
		}

		.supergroup{
			float: left;
			border-right: 1px solid #dadada;
			width: 66%;
			margin-top: 40px;
			padding-right: 40px;
			margin-bottom: 40px;
		}

		.group2{
			border-bottom: 0;
			padding-bottom: 0px;
			margin-bottom: -14px;
		}

		.group3{
			float: left;
			width: 33%;
			border-bottom: 0;
			margin-top: 40px;
			padding-top: 0;
			padding-left: 40px;

			.ligne{
				margin-bottom: 30px;
			}
			.skinfileinput{
				max-width: 100%;
				margin-bottom: 30px;
			}
		}

		.group4{
			clear: left;
			border-top: 1px solid #dadada;
			border-bottom: 1px solid #dadada;
			margin-top: 40px;
			padding-bottom: 25px;
			margin-bottom: 40px;
		}

	}
}