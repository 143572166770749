// WEBSITES


.websites {
	.body{
		background-color: #ececec;
	}
	.section__intro {
		overflow: visible;
		.lame__content{
			overflow: visible;
			min-height: 0 !important;
			padding-top: 2rem;

			.bg-lame img{
				top: 0;
				transform:translateY(0);
			}

			.block-content{
				padding: 0;
				h1,h2{
					padding: 0 6.25%;
				}
				p{
					padding: 0 6.25%;
				}
			}
		}
	}

	.section__intro .lame__content .block-content .filters{
		background-color: #ececec;
		padding: 10px 10px 0;
	}

	.masonrylike{
		position: relative;
		z-index: 0;
		overflow: hidden;
		padding: 5px;
		margin: auto;
	}
	.column{
		float: left;
		width: 50%;
	}
	.colcount1 .column{
		float: none;
		width: 75%;
		margin: auto;
	}
	
	.tile{
		
		padding: 5px;

		.block-content{
			background-color: #fff;
		    padding: 1.5rem 6.25%;
		}
		.block-content h1{
			font-weight: 400;
			font-size: 1.1rem;
			margin-bottom: 1rem;
		}
		.block-content h2{
			font-weight: 400;
			font-size: 1.1rem;
			margin-bottom: 1rem;
		}
		.block-content p{
			font-weight: 400;
			font-size: 1.1rem;
			margin: 1rem auto;
		}
		.block-content a{
			font-weight: 400;
			font-size: 1.1rem;
		}
	}
}