// VALUES

.values {

	.block-content h1{
		font-size: 1.8rem;
	}

	.block-content h2{
		font-size: 1.8rem;
	}

	.block-content p{
		font-size: 1.2rem;
	}
}