// Buttons
button{
	outline: none;
	cursor:pointer;
}
.btn {
	text-transform: uppercase;
	display: inline-block;
}

.btn--secondary {
	font-size: 0.8em;
	margin: 20px 0;
}