// Variables

$mainColor: #000;
$secondaryColor: #fff;
$peugeotBgColor: #152032;
$darkBgColor: #152946;
$darkBdColor: #265d86;
$menuColor: #64869e;
$azurColor: #007edb;
$azurdarkColor: #0062c5;
$linkoverColor: #0c2d47;
$lightgray: #ececee;
$gray: #b4b4b4;
$textDarkColor: #033153;
$validColor: #019824;
$errorColor: #891422;
$headerHeightMobile: 60px;
$headerHeightTablet: 70px;
$headerHeightDesktop: 94px;
$headerBrandTablet: 114px;
$headerBrandDesktop: 155px;