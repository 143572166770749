// WEBSITES

.websites {
	.section__intro .block-content p.label{
		font-size: 1.4rem !important;
	}
	.masonrylike{
		padding: 8px;
		max-width: 1024px;
	}
	.tile{
		padding: 8px;
		.block-content h1, .block-content h2{
			font-size: 1.8rem;
		}
		.block-content p{
			font-size: 1.4rem;
		}
		.block-content a{
			font-size: 1.4rem;
		}
	}
}