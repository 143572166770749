// WEBSITES


.websites {
	.section__intro {
		.lame__content{
			.bg-lame img{
				top: auto;
				bottom: 0;
			}
		}
	}
}