//------------------------------------
// header-63-1008


header {
	height: $headerHeightTablet;

	.logopeugeot{
		position: absolute;
		width: $headerBrandTablet;
		height: $headerHeightTablet;
	    line-height: $headerHeightTablet;
	    text-align: center;
		left: 0;
		right: auto;
		margin: auto;
		top: 0;
		transform:translateY(0%);
	}
	.logopeugeot img{
		width: 80px;
		height: auto;
		margin: auto;
	}

	.mobile.contact{
		display: none;
	}

	.icon{
		width: 53px;
		height: 53px;
		fill:$menuColor;
	}

	.main-nav{
		width: calc(100% - #{$headerBrandTablet});
		transition:none;
		height: $headerHeightTablet;
	    top: 0;
	    left: $headerBrandTablet;
	    right: 0;
	    display: table;
	    table-layout: fixed;

		.main-nav__rub{
			display: table-cell;
			width: auto;
			padding-left: 0px;
			min-height: 0;
			border-bottom: 1px solid $darkBdColor;
			border-left: 1px solid $darkBdColor;
			text-align: center;
			vertical-align: bottom;
			padding-bottom: 7px;
			transition: 0.2s ease-in-out;
		}
		.main-nav__rub span{
			line-height: normal;
			font-size: 1rem;
		}
		.main-nav__rub:hover{
			text-decoration: none;
			background-color: $azurColor;
		}
		.main-nav__rub .icon{
			width: 40px;
			position: absolute;
			left: 0px;
			right: 0;
			margin: auto;
			top: auto;
			transform:translateY(0%); 
			transition:0.2s ease-in-out;
			bottom: 16px;
		}
		.main-nav__rub:hover .icon{
			fill:$secondaryColor;
		}
		.main-nav__alt{
			border-left: 1px solid $darkBdColor;
			display: table-cell;
			min-height: 0;
			/* border-bottom: 1px solid $darkBdColor; */
			border-bottom:0;
		}
		.main-nav__lang{
			display: block;
			min-height: 0;
			height: $headerHeightTablet/2;
			line-height: $headerHeightTablet/2;
			border-bottom: 1px solid $darkBdColor;
			font-size: 1.3rem;
			text-align: center;
		}
		.main-nav__lang a{
			display: inline-block;
			border-right: 0;
			line-height: normal;
		}
		.main-nav__lang span{
			display: inline-block;
			vertical-align: middle;
		}
		.main-nav__socials{
			height: $headerHeightTablet/2;
			min-height: 0;
		}
		.main-nav__socials a{
			vertical-align: middle;
			text-align: left;
			font-size: 0;
			transition:0.2s ease-in-out;
			border-bottom: 1px solid $darkBdColor;
		}
		.main-nav__socials a:hover{
			background-color: $azurColor;
		}
		.main-nav__socials a .icon{
			width: 80%;
			height: $headerHeightTablet/2;
			transition:0.2s ease-in-out;
		}
		.main-nav__socials a .icon {
			top:0;
			transform:translateY(0%);
		}
		.main-nav__socials a:hover .icon{
			fill:$secondaryColor;
		}
	}

	.burger-menu {
		display: none;
	}

}