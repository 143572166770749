// HOME

.home {

	.simplecta{
		font-size: 1.3rem;
	}

	.simplecta:after{
	    border-right-color: #fff;
	    border-bottom-color: #fff;
	}

	.lame__content .paris-saigon h2{
		background:url(../images/signs/django-adventure.png) no-repeat;
		background-size: contain;
		width: 120px;
		height: 110px;
	}
	.lame__content .moto3 h2{
		background:url(../images/signs/moto3.png) no-repeat;
		background-size: contain;
		width: 120px;
		height: 30px;
	}
	.lame__content .cent-vingt-ans h2{
		background:url(../images/signs/cent-vingt-ans.png) no-repeat;
		background-size: contain;
		width: 120px;
		height: 84px;
		margin-top:20px;
	}
	.lame__content .moto3 h3{
		text-transform: uppercase;
		font-weight: 300;
		font-size: 2rem;
		margin: 0 auto -10px;
	}

	.section__slider{
		.lame-last-block-empty{
			.lame__content{
				min-height: 400px;
				& + .lame__content {
					min-height: 100px;
				}
			}
		}
	}
	
}