// PRESS
.press{

    .pressref{
        margin-top: 16px;
    }
    .presslogo{
        width: 35%;
    }
    .block-content h1{
        margin-top: 16px;
        font-size: 1.8rem;
    }
    .block-content h2{
        margin-top: 16px;
        font-size: 1.8rem;
    }
    .block-content p{
        font-size: 1.4rem;
    }
    .block-content p.pressdate{
        position: static;
        margin-top: 30px;
        font-size: 1.3rem;
    }
    .presslink{
        top: 0;
        font-size: 1.3rem;
    }

}