// SITEMAP

.sitemap {
	.section__rubriques{
		align-items: center;
		.lame__content{
			padding-top: 2em;
			padding-bottom: 2em;
			display: block;
			.block-content{
				margin-bottom: 1em;
				h2{
					display: inline-block;
					border-bottom: 1px solid $azurColor;
					padding-bottom: 0.6em;
					margin-bottom: 0.8em;
					font-size: 1.6rem;
					font-weight: 400;
				}
				ul{
					margin-bottom: 1em;
				}
				li{
					line-height: 1.6
				}
			}
		}
	}
}