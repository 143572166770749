// CAREERS

.careers {
	.lame{

		&.nooffer p{
			font-size: 2.4rem;
		}

		&.jobprez .block-content {
			width: 60%;

			p{
				margin: 20px 0;
			}
		}

		.jobpubdate, .jobtype, .jobtitle, .joblocation{
			line-height: normal;
		}

	}

	.jobcard{
		padding-top: 50px;

		h1,h2{
			font-size: 3.2rem;
		}
		h3{
			font-size: 2.8rem;
			font-weight: 300;
			margin-bottom: 60px;
		}
		.job-titles h3 {
			font-size: 1.6rem;
			font-weight: 600;
			text-align: left;
		}
		.block-content{
			padding-top: 40px;
		}
		h4, p, ul{
			font-size: 1.6rem;
		}
		.cardblock{
			p, ul{
				margin-bottom: 2rem;
			}
		}
	}

}