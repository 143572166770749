// TERMS

.accordeon{
	padding: 40px 0;
	width: 700px;
	margin: auto;

	.block-content{
		padding: 0;
		h1{
			margin-bottom: 40px;
		}
	}

	section.block-content{
		padding-right: 10px;

		h2{
			padding-right: 15px;
		}
		p{
			font-size: 1.4rem;
		}
		
	}
}