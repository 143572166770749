// RANGE


.range .block-content h1 {
    font-size: 7.2rem;
}

.range .block-content h2 {
    font-size: 7.2rem;
}

.range .block-content h2 span {
    font-size: 4.7rem;
}


.range .block-content h2.cc200 span.cc-more {
    font-size: 1.8rem;
    top: 53px;
}

.range .block-content h2.wheels3 span {
    font-size: 7.2rem;
}

.range .block-content p {
    font-size: 1.6rem;
}

.simplecta {
    font-size: 1.4rem;
}