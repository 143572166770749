// CAREERS

.careers {
	.lame{
		&.jobprez{
			border-top: 1px solid #d4d4d4;
			border-bottom: 1px solid #d4d4d4;

			.lame__content{
				border-left: 0;
				border-right: 1px solid #d4d4d4;
				border-top: 0;
				border-bottom: 0;
				min-height: 28.6vw;
				display: block;
				padding: 20px 0 100px;

				&:last-child{
					border-left: 1px solid #d4d4d4;
					border-right: 0;
				}
			}
			
			.block-content {
				width: 80%;
				padding: 0;
				margin: auto;
				position: static;
				
				p{
					margin: 16px 0;

					&.jobcta{
						margin: 0;
						position: absolute;
						bottom: 0;
					}
				}

				.buttoncta{
					margin-bottom: 40px;
				}
			}
		}


		.jobpubdate{
			font-size: 1.4rem;
		}
		.jobtype{
			font-size: 2.8rem;
		}
		.jobtitle{
			font-size: 2.8rem;
		}
		.joblocation{
			font-size: 1.6rem;
		}
	}

	.jobcard{
		width: 80%;
		margin: auto;


		h1, h2, h3{
			text-align: center;
		}

		.job-titles h3 {
			text-align: left;
			text-transform: none;
		}

		h3{
			margin-bottom: 4rem;
		}

		.block-content{
			padding-left: 0;
			padding-right: 0;
		}

		.closecard{
			right: 0;
			width: 30px;
			height: 30px;

			.icon-close{
				width: 30px;
				height: 30px;
			}
		}
		.cardblock{
			display: table;
			width: 100%;
		}
		.ligne{
			display: table-row;
		}
		.datatitle, .dataresult {
			display: table-cell;
			padding: 1rem 0;
		}
		.datatitle{
			width: 25%;
		}
	}
}