// SITEMAP

.sitemap {
	.section__rubriques{
		.lame__content{
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
		    min-height: 0px;
		    height: 560px;
		    align-items: baseline;
		    align-content: space-around;
		    .block-content{
		    	margin-left: 0;
		    	margin-right: 0;
		    	padding: 0;
		    	flex-grow: 1;
		    }
		}
	}
}