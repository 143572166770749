// RANGE


.range .bg-lame img {
    top: 0; 
    transform:translateY(0%);
}

.range .block-content h1 {
	font-size: 5.6rem;
}

.range .block-content h2 {
    font-size: 5.6rem;
}

.range .block-content h2 span {
    font-size: 3.6rem;
    text-transform: lowercase;
    padding-left: 6px;
}

.range .block-content h2.cc50 {
    color: #c4b507;
}

.range .block-content h2.cc50 span {
    color: #9f9200;
}

.range .block-content h2.cc125 {
    color: #30a9ee;
}

.range .block-content h2.cc125 span {
    color: #007ddb;
}

.range .block-content h2.cc200 {
    color: #9a8aff;
}

.range .block-content h2.cc200 span.cc-up {
    color: #675bb3;
    position: absolute;
    top: 0px;
}

.range .block-content h2.cc200 span.cc-more {
    color: #a395fa;
    font-size: 1.6rem;
    position: absolute;
    text-transform: uppercase;
    top: 40px;
}

.range .block-content h2.wheels3 {
    text-transform: capitalize;
    color: #ff9e35;
}

.range .block-content h2.wheels3 span {
    text-transform: capitalize;
    color: #e07500;
    font-size: 5.6rem;
}