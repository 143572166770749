//------------------------------------
// header-40-640
$headerHeightMobile: 74px;

header {
	height: $headerHeightMobile;

	.logopeugeot{
		width: 85px;
	}

	.mobile.contact,
	.mobile.contact .icon{
		width: 50px;
		height: 50px;
	}

	.main-nav{
		top: $headerHeightMobile;
		height: calc(100vh - #{$headerHeightMobile});
	}

	.burger-menu {
	    height: calc(#{$headerHeightMobile} - 1px);
	    width: $headerHeightMobile;

		span:nth-child(1) {
			top: 30px;
		}

		span:nth-child(2),span:nth-child(3) {
			top: 36px;
		}

		span:nth-child(4) {
			top: 42px;
		}
	}

	.burger-menu.open {

		span:nth-child(1),
		span:nth-child(4) {
			top: 30px;
		}
	}
}

.container{
	&.open-mobile-menu{
		.main-nav{
			top: $headerHeightMobile;
		}
		header.withCookieBanner .main-nav{
			top: calc(80px + #{$headerHeightMobile});
		}
		&:after{
		    height: calc(100% - #{$headerHeightMobile});
		    top: $headerHeightMobile;
		}
	}
}