// RANGE


.range .block-content h1 {
    font-size: 4rem;
    margin-bottom: 0;
}

.range .block-content h2 {
    font-size: 4rem;
    margin-bottom: 0;
}

.range .block-content h2 span {
    font-size: 2.5rem;
    text-transform: lowercase;
    padding-left: 6px;
}

.range .block-content p {
    font-size: 1.2rem;
}

.range .block-content h2.cc200 span.cc-more {
    font-size: 1rem;
    top: 31px;
}

.range .block-content h2.wheels3 span {
    font-size: 4rem;
}