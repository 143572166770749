// Mixins

@mixin borderradius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

@mixin  transition($prop) {
	-webkit-transition: $prop;
	-moz-transition: $prop;
	-o-transition: $prop;
	transition: $prop;
}