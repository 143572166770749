// HOME

.home {
	
	.block-content .paris-saigon h2{
		width: 382px;
		height: 128px;
	}
	.block-content .moto3 h2{
		width: 274px;
		height: 68px;
	}
	.block-content .moto3 h3{
		font-size: 3.2rem;
	}
	.block-content.cent-vingt-ans h3{
		font-size: 2rem;
		font-weight: normal;
	}
	
	.lame__content .cent-vingt-ans h2{
		width: 220px;
		height: 155px;
	}
	
}