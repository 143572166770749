// SUCCESS STORY

.success-story {
  .body{
    background-color: #f0f0f0;
  }

  .block-content {
      padding-top: 15px;
      padding-bottom: 15px;
  }

  .section__intro .lame__content {
    min-height: auto !important;
    .block-content {
      padding-left: 3.125%;
      padding-right: 3.125%;
      padding-bottom: 0;
      h1,h2 {
        .small-print{
          display: block;
          text-transform: none;
          font-size: 1.4rem;
          margin-top: 1em;
          font-weight: 400;
        }
      }
      h3{
        font-size: 2.6rem;
        color: $azurColor;
        font-weight: 400;
        margin-bottom: 12px;
      }
      h5{
        font-size: 1.3rem;
        font-weight: 400;
        color: $azurColor;
      }
      ul p{
        margin-bottom: 0;
        margin-top: 8px;
      }
      .world-story-carousel{
        .timeline-world{
          width: 70%;
          margin: auto;
        }
        p{
          font-size: 1.2rem;
        }
      }
      .timeline-peugeot, .timeline-world{
        padding-top: 1px;
        padding-bottom: 1px;
      }

      .ssCarousel, .success-story-carousel.nocarousel{
        padding-top: 20px;
        padding-bottom: 20px;
        position: relative;
      }
      .wsCarousel, .world-story-carousel.nocarousel{
        padding-top: 20px;
        padding-bottom: 20px;
        position: relative;
        .bx-viewport{
          z-index: 1;
        }
        &:after{
          content: '';
          display: block;
          border-top: 2px solid #fff;
          width: 200%;
          height: 100%;
          left: -100px;
          right: -100px;
          position: absolute;
          z-index: 0;
          top: 0;
          bottom: 0;
          background-color: rgba(201,201,201,0.3);
        }
      }

      .timeline-peugeot picture {
        width: 240px;
        display: block;
        margin: 0 auto;
        img{
          margin: 0 auto;
        }
      }
    }
  }

  .timeline-detail {
    display: none;
    position: fixed;
    top: 60px;
    left: 0; 
    background-color: rgba(0, 0, 0, 0.7);
    height: calc(100vh - 60px);
    z-index: 10000;

    .timeline-detail-content {
      background-color: #fff;
      padding: 5% 10%;
      text-align: left;
      h5{
        font-size: 2rem;
        font-weight: 400;
        margin-bottom: 15px;
        color:$mainColor;
      }
      picture {
        width: 100%;
        img{
          width: 100%;
          margin-bottom: 20px;
        }
      }
      p{
        font-size: 1.3rem;
        margin-bottom: 20px;
      }
      a.simplecta{
        font-size: 1.4rem;
      }
    }

    .timeline-detail-close {
      text-indent: -999px;
      overflow: hidden;
      background-color: #007edb;
      width: 30px;
      height: 30px;
      position: absolute;
      right: 0;
      top: 0;

      &::before, &::after {
        height: 1px;
        width: 20px;
        content: "";
        background-color: #fff;
        position: absolute;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-transform-origin: center center;
        -ms-transform-origin: center center;
        transform-origin: center center;
        top: 50%;
        left: 50%;
        margin-left: -10px;
      }

      &::after {
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

    }
  }


  /* Carousel */
  .bx-wrapper {
    .bx-viewport {
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
      background-color: transparent;
    }

    .bx-controls-direction a {
      background: none;
      text-indent: 0;
      top: 100px;
      left: 0;
      z-index: 10;
    }

    .bx-controls-direction a.bx-next {
      right: 0;
      left: auto;
    }

    .bx-controls-direction a::after {
      content: '';
      width: 12px;
      height: 12px;
      border-right: 1px solid #007edb;
      border-bottom: 1px solid #007edb;
      top: 50%;
      transform: rotate(-225deg) translateY(-50%);
      position: absolute;
      transition: 0.25s ease-in-out;
      transform-origin: 50% 0;
      left: 0;
      right: 0;
      margin: auto;
    }

    .bx-controls-direction a.bx-next::after {
      transform: rotate(-45deg) translateY(-50%);
    }
  }

  #paris-saigon-detail{
    display: none;
  }

  [class*="section__"] .bg-lame img {
    top: auto;
    transform: translateY(0);
    bottom: 0;
  }

  .section__moto3-detail-3{
    .lame__content{
      min-height: 59.375vw;
    }
  }

  .section__moto3 .lame__content{
    h1,h2{
      background:url(../images/signs/logo-moto3.png) no-repeat;
      background-size: contain;
      width: 158px;
      height: 40px;
      margin-left: 0;
    }
    h3{
      font-size: 1.8rem;
      font-weight: 600;
      text-transform: none;
    }
  }

  .section__moto3-detail-1 h3, .section__moto3-detail-2 h3{
    font-size: 1.8rem;
    font-weight: 400;
    text-transform: none;
  }

  .section__paris-saigon .lame__content{
    h1,h2{
      background:url(../images/signs/paris-saigon.png) no-repeat;
      background-size: contain;
      width: 207px;
      height: 69px;
      margin-left: 0;
    }
  }

  .section__loose-project .lame__content h2 {
    background: url(../images/signs/logo-loose-project.jpg) no-repeat;
    background-size: contain;
    width: 150px;
    height: 119px;
    margin-left: 0;
  }

  .section__deusex .lame__content {
    color:#fff;
    background-color: #000;
    h2 {
      background: url(../images/signs/logo-deusex.jpg) no-repeat;
      background-size: contain;
      width: 300px;
      height: 58px;
      margin-left: 0;
    }
  }

  .section__120ans-detail-2 {
    background-color: #192652;
    img.schedoni{
      display: block;
    width: 100px;
    margin: 0 auto 10px;
    }
  }


  [class*="section__"] .lame__content h3 {
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: none;
  }

  .withYTvideo{
    .lame__content{
      .youtube-embed{
        display: none;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  
}
