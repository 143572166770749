//-------------------------------------------------
// body-80-1280

.container {
	padding-top: $headerHeightDesktop;
}

.block-content h1{
	font-size: 3.2rem;
}
.block-content h2{
	font-size: 3.2rem;
}
.block-content p{
	font-size: 2rem;
}
.simplecta{
	font-size: 1.4rem;
}
.bx-wrapper .bx-pager, .bx-wrapper .bx-controls-auto{
	bottom: 20px;
}
