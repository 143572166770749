// HOME

.home {

	.section__slider{
		.lame-last-block-empty{
			.lame__content{
				min-height: 40vw;
				& + .lame__content {
					min-height: 40vw;
				}
				.block-content{
					padding-bottom:20px; 
				}
				.bg-lame img{
					width: 120%;
				}
			}
		}
	}
	
}