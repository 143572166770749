// CONTACT

.contact {
	.section__intro .block-content {
        padding: 0 17.25%;
    	}
    	
	form{
		width: 93.75%;
		margin: 0 auto 40px;
		text-align: center;

		h3{
			font-size: 2rem;
			font-weight: 400;
			margin-bottom: 15px;

			span{
				color:$azurColor;
			}
		}

		label{
			font-weight: 300;
			margin-right: 10px;
			margin-bottom: 7px;
		}

		.label{
			font-weight: 400;
		}
		
		input[type="text"], textarea{
			width: 100%;
			padding: 0 10px;
		}

		textarea{
			height: 160px;
			resize: none;
			padding: 10px;
		}

		.group{
			width: 93.33%;
			margin: auto;
			padding: 25px 0 20px;
			text-align: left;
		}

		.group1{

			.skinradio{
				margin-right: 15px;
			}

			.radiowrapper{
				margin: 7px 0;
			}
			
			.radiowrapper:last-child{
				margin-bottom: 0;
			}
		}
		
		.group2, .group3{
			border-bottom: 1px solid #dadada;
			
			.label{
				display: block;
			}

			.ligne{
				margin-bottom: 15px;
			}
		}

		.group4{
			margin-bottom: 10px;
		}

		.rbgtitle{
			margin-bottom: 10px;
			border-bottom: 1px solid #dadada;
		}
		
		.indic{
			color:#aaa;
			font-size: 1.2rem;
		    margin-top: -7px;
		    margin-bottom: 10px;
		}

		.skinfileinput{
			margin-bottom: 20px;
		}

		.optintext{
			margin-bottom: 20px;
		}

		input[type="submit"]{
			display: block;
			width: 100%;
		}
	}

	.noform{
		background-color: #f0f0f0;
		margin: auto;
		padding: 20px 0;

		.block-content {
			h1,h2{
				font-size: 2rem;
				font-weight: 400;
				text-align: center;
			}
			h3{
				font-size: 1.8rem;
				font-weight: 300;
				padding-left: 60px;
				line-height: 57px;
				background-repeat: no-repeat;
				background-position: 0 50%;
			}
			.par-courrier{
				padding-top: 20px;
				padding-bottom: 20px;
				border-bottom: 1px solid #c0c0c0;

				h3{
					background-image: url(../images/signs/courrier.png);
				}
			}
			.par-tel{
				padding-top: 20px;

				h3{
					background-image: url(../images/signs/telephone.png);
				}

				.telvert{
					background-image: url(../images/signs/numero-vert.png);
					background-repeat: no-repeat;
					width: 100%;
					height: 0;
					padding-bottom: 10%;
				}
			}
		}
	}
}