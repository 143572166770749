//-------------------------------------------------
// body-40-640


.container {
	padding-top: 74px;
	/* padding-bottom: 48px; */
}


.lame{
    flex-direction: row;
}
.lame-direction-reverse{
	flex-direction: row-reverse;
}
.lame__content{
	min-height:34.8vw;
	flex-basis: 0;
}
.section__intro .lame__content{
	min-height:34.8vw !important;
}
.lame-var1 .lame__content{
	min-height: 28vw;
}
.bg-lame img{
	width: 100%;
	top:50%;
	transform:translateY(-50%);
}
.lame-last-block-empty .lame__content:last-child{
	flex-grow: 0;
}
.section__valeurs .lame__content{
	padding-top: 30px;
}
.block-content {
	padding:0 12.5%;
}
.block-content h1{
	margin-bottom: 14px;
}
.block-content p{
	margin-top: 14px;
}
.block-content .buttoncta{
	margin-top: 10px;
}
.bx-wrapper .bx-pager, .bx-wrapper .bx-controls-auto{
	bottom: 10px;
}

.section__intro .block-content h1{
	font-size: 2rem !important;
}
.section__intro .block-content h2{
	font-size: 2rem !important;
}

.section__intro .block-content p{
	font-size: 1.4rem !important;
}

.section__intro .block-content{
	padding-left: 28%;
	padding-right: 28%;
}

.spanbreak{
	display: inline;
}

.lame.section__career-intro .lame__content,
.lame.nooffer .lame__content {
    min-height: 11.1vw !important;
}