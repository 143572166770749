// CAREERS

.careers {
	.lame{

		&.nooffer p{
			font-size: 1.8rem;
		}
		
	}

	.jobcard{
		width: 71.4%;
		max-width: 1000px;

	}
}