// TERMS

.accordeon{
	padding: 60px 0;
	width: 1000px;

	.block-content{
		padding: 0;
		h1{
			margin-bottom: 40px;
		}
	}

	section.block-content{
		padding-right: 40px;

		h2{
			font-size: 2rem;
			font-weight: 300;
			padding-right: 15px;

			&:after{
				right: -10px;
			}
		}
		p{
			font-size: 1.4rem;
		}
		
	}
}