// LOCATIONS

.locations {
	section.block-content {
		h2{
			font-size: 2rem;
			margin-top: 36px;
		}
		.blocklegend{
			flex-direction: column;
			height: 650px;
			padding:20px 0;
			.maplegend{
				width: 30%;
				padding-right: 5%;
				margin-bottom: 20px;
				h3{
					font-size: 1.6rem;
					padding-left: 25px;
					&:before{
						top:6px;
					}
				}
				ul{
					li{
						font-size: 1.4rem;
						img{
						}
					}
				}
				&.lg1{
					margin-right: 0;
					h3{
					}
				}
				&.lg2{
					margin-right: 0;
					order: 2;
					h3{
					}
				}
				&.lg3{
					margin-left: 0;
					order: 3;
					h3{
					}
				}
				&.lg4{
					margin-left: 0;
					order: 4;
					h3{
					}
				}
				&.lg5{
					padding-right: 0;
					width: 70%;
					order: 5;
					h3{
					}
					ul{
						column-count: 3;
						li{
						}
					}
				}
			}
		}
	}
}