//------------------------------------
// header-80-1280


header {
	height: $headerHeightDesktop;
	max-width: 1600px;

	.logopeugeot{
		width: $headerBrandDesktop;
		height: $headerHeightDesktop;
	    line-height: $headerHeightDesktop;
	}
	.logopeugeot img{
		width: 109px;
	}
	.main-nav{
		width: calc(100% - #{$headerBrandDesktop});
		height: $headerHeightDesktop;
	    left: $headerBrandDesktop;

		.main-nav__rub {
			padding-bottom: 10px;
		}

		.main-nav__rub .icon{
			width: 53px;
			bottom: 28px;
		}

		.main-nav__rub span{
			font-size: 1.4rem;
		}

		.main-nav__lang{
			height: $headerHeightDesktop/2;
			line-height: $headerHeightDesktop/2;
			font-size: 1.8rem;
		}
		.main-nav__socials{
			height: $headerHeightDesktop/2;
		}
		.main-nav__socials a .icon{
			height: $headerHeightDesktop/2;
		}
	}

}