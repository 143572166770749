// Erreur 404

.error404 {
	.lame{
		.block-content{
			h1{
				font-size: 2.4rem;
			}
			h2{
				font-weight: 300;
				font-size: 1.8rem;
				text-transform: uppercase;
			}
			p{
				font-weight: 300;
			}
			a{
				display: block;
				margin: 1rem 0;
			}
		}
	}
}