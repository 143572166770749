// PRESS
.press{
    .lame__content{
        min-height: 0;
    }
    .lame__content:first-child{
        min-height: 70vw;
    }
    .section__intro .lame__content{
        min-height: 78.125vw;
    }

    .bg-lame {
        width: 100%;
    }

    .section__intro .bg-lame {
        width: auto;
    }
    .bg-lame img {
        top: 0;
        transform: translateY(0%);
        border: 6.25vw solid #fff;
    }
    .section__intro .bg-lame img {
        border: none;
    }
    .section__intro .block-content {
        padding: 0 17.25%;
    }
    .block-content h1{
        text-transform: none;
        margin-top: 30px;
    }
    .block-content h2{
        text-transform: none;
        margin-top: 30px;
    }

    .pressref{
        color: #8c8c8c;
        position: relative;
    }
    .presslogo{
        width: 25%;
    }
    .pressdate, .presslink{
        position: absolute;
        right: 0;
        display: block;
        font-size: 1.3rem;
    }
    .pressdate{
    top: 0;    
    }
    .presslink{
        top:20px;
    }
    .block-content p.pressdate{
        margin: 0;
    }
}