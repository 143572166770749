// PRESS
.press{
    .lame__content, .lame__content:first-child, .section__intro .lame__content{
        min-height: 34.8vw;

    }
    
    .bg-lame img {
        border: 0;
    }
    .block-content {
        padding: 0 13% 0 24%;
    }
    .lame-direction-reverse .block-content {
        padding: 0 24% 0 13%;
    }
    .block-content h1{
        margin-top: 16px;
        font-size: 1.6rem;
    }
    .block-content h2{
        margin-top: 16px;
        font-size: 1.6rem;
    }
    .pressref{
        margin-top: 16px;
    }
    .block-content p{
        font-size: 1.2rem;
    }
    .block-content p.pressdate{
        font-size: 1.1rem;
    }

}