// WEBSITES

.websites {

	.section__intro .lame__content{
		min-height: 34.8vw !important;
		padding-top: 0;

		.block-content{
			padding-left: 10%;
	    	padding-right: 10%;
			h1,h2{
				padding: 0;
			}
			p{
				padding: 0;
			}
			.filters{
				background-color: transparent;
				padding: 10px 0 0;
				.selectric-wrapper{
					width: 40%;
					display: inline-block;
					margin: 0 10px;
					vertical-align: text-top;
					p.label{
						text-align: left;
					}
				}
			}
		}
	}


	.masonrylike{
		padding: 6px;
	}
	.column{
		float: left;
		width: 33.3%;
	}
	.colcount1 .column,.colcount2 .column{
		width: 320px;
	}
	.colcount2{
		width: 656px;
	}
	.tile{
		padding: 6px;
		.block-content h1, .block-content h2{
			font-size: 1.6rem;
		}
		.block-content p{
			font-size: 1.2rem;
		}
		.block-content a{
			font-size: 1.2rem;
		}
	}
}