// SUCCESS STORY

.success-story {
	.section__intro{
		max-height: none;
	}	
    .section__intro .lame__content {
        .block-content {
            padding-top: 40px;
            h1,h2 {
                font-size: 3.2rem !important;
                line-height: 1.6;
              .small-print{
                font-size: 2rem;
              }
            }
            h3{
                font-size: 3.2rem;
            }
            h4{
                font-size: 1.6rem;
            }
            h5{
                font-size: 1.4rem;
            }
            ul {
            	p{
            		font-size: 1.4rem !important;
            	}
            	&.mq-select{
            		margin-top: 1.5em;
            		margin-bottom: 2em;
            		letter-spacing: 4em;
            		li{
            			padding-bottom: 1em;
            			font-size: 1.4rem;
            			letter-spacing: normal;
            		}
            	}
            }
            .ssCarousel{
                &:before{
                    top:222px;
                }
            }
            .success-story-carousel{
            	margin-left: 36%;
            	li{
                    &:before{
                        background-color: #e3e3e3;
                        top:185px;
                    }
                    &:after{
                        top:200px;
                    }
                    &.activeSlide:after{
                        top:192px;
                    }
            	}
                .timeline-peugeot{
                    width: 100%;
                    >picture{
                        width:100%;
                    }
                    h3,h4,p{
                        margin-left: auto;
                        margin-right: auto;
                        width: 80%;
                    }
                }
            }
            .world-story-carousel{
            	margin-left: 36%;
                p{
                    font-size: 1.3rem !important;
                }
            	
              .timeline-world{
                width: 100%;
                h5,p{
                    margin-left: auto;
                    margin-right: auto;
                    width: 70%;
                }
              }
            }
        }
        .bx-wrapper {
    	    .bx-controls-direction a {
    	      top: 100px;
    	      left: -20px;
              &:after{
                width: 18px;
                height: 18px;
              }
    	    }
    	    .bx-controls-direction a.bx-next {
    	      right: -20px;
    	      left: auto;
    	    }
    	}
    }

    [class*="section__"]{
        .block-content{
            padding-left: 20%;
            padding-right: 20%;
        }
        .lame__content{
            h1,h2{
                margin-bottom: 1em;
            }
            h3, h4{
                font-weight: 400;
                margin-bottom: 1em;
            }
            h3{
                font-size: 2.2rem;
            }
            h4{
                font-size: 2rem;
            }
            p{
                font-size: 1.6rem;
                margin-top: 0;
                margin-bottom: 12px;
            }
            .simplecta{
                font-size: 1.6rem;
                font-weight: 400;
            }
            .bg-lame img{
                top: 50%;
                transform: translateY(-50%);
                bottom: auto;
            }
        }
    }
    .section__moto3 .lame__content{
        h1,h2{
            width: 229px;
            height: 57px;
            margin-left: 0;
        }
    }
    .section__paris-saigon .lame__content{
        h1,h2{
            width: 365px;
            height: 123px;
            margin-left: 0;
        }
    }
    .section__moto3, .section__paris-saigon{
        .bg-lame img{
            top: 50%;
            bottom: auto;
            transform:translateY(-50%);
        }
    }
    .section__moto3-detail-3{
        position: relative;
        overflow: hidden;
        .thesliderbackground img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
    }
}
