// VALUES

.values {

	.block-content h1, .section__intro .block-content h1{
		font-size: 3.2rem;
		font-weight: 300;
	}

	.block-content h2, .section__intro .block-content h2{
		font-size: 3.2rem;
		font-weight: 300;
	}

	.block-content p{
		font-size: 1.6rem;
	}

	.section__intro .block-content p{
		font-size: 2rem;
		font-weight: 300;
	}
}