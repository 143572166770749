// CONTACT

.contact {
	form{
		max-width: 1000px;
		margin-left: auto;
		margin-right: auto;

		.group1{
			border-bottom: 1px solid #dadada;

			.radiowrapper{
				display: inline-block;

				label{
					margin-right: 20px;
				}
			}
		}

		.rbgtitle{
			border-bottom: 0;
		}

		.parcol{
		    column-count: 2;
		    column-gap: 30px;

			.ligne{
				break-inside: avoid;
				min-height: 66px;
			}
		}

		.skinfileinput{
			max-width: 50%;
		}

		input[type="submit"]{
			display: inline-block;
			width: auto;
		}
	}

	.noform{
		padding: 40px 20px;

		.block-content {
			padding: 0;
			max-width: 760px;

			h1,h2{
				font-weight: 300;
				padding-bottom: 40px;
			}
			h3{
				font-size: 2rem;
				padding-left: 60px;
				display: inline-block;
				margin-bottom: 20px;
			}
			.othercontact{
			    display: table;
			    table-layout: fixed;
			    width: 100%;
			}
			.par-courrier{
				display: table-cell;
				vertical-align: top;
				text-align: right;
				padding-top: 0px;
				padding-bottom: 0px;
				padding-right: 45px;
				border-bottom: 0;
				border-right: 1px solid #c0c0c0;

				h3{
					padding-left: 0px;
					padding-right: 60px;
					background-position: 100% 50%;
				}
			}
			.par-tel{
				display: table-cell;
				vertical-align: top;
				padding-top: 0px;
				padding-bottom: 0px;
				padding-left: 45px;
			}
		}
	}
}