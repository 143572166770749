// Erreur 404

.error404 {
	.lame{
		.block-content{
			h1{
				font-size: 9rem;
			}
			h2{
				font-size: 3.2rem;
			}
			a{
				margin: 2rem 0;
			}
		}
	}
}