.cookies {
	padding: 20px 50px 12px 20px;
	max-width: 1600px;
	min-height: 80px;
	font-size: 1.4rem;
	text-align: center;
	br{
		display: inline;
	}
	.cookies__close {
		height: 30px;
		svg{
			width: 30px;
			height: 30px;
		}
	}
}
