//------------------------------------
// #BASE
//------------------------------------

@import "variables";
@import "mixins";
@import "fonts";
@import "headings";
@import "links";



// w1280
/* @media only screen and ( min-width: 80rem ) { */
@media only screen and ( min-width: 1280px ) {
  @import "links-80-1280";
}