// SUCCESS STORY

.success-story {

  .section__intro .lame__content {
    .block-content {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        width: 100%;
        h1,h2 {
        	font-size: 2rem;
          .small-print{
            font-weight: 300;
            margin-top: 0;
          }
        }
        h3{
        	font-size: 2.6rem;
        	margin-bottom: 6px;
        }
        ul {
        	p{
        		font-size: 1.2rem !important;
        	}
        	&.mq-select{
        		display: inline-block;
        		border-bottom: 2px solid rgba(0,0,0,0.09);
        		margin-top: 1.5em;
        		margin-bottom: 2em;
        		letter-spacing: 4em;
        		li{
        			padding-bottom: 1em;
        			position: relative;
        			display: inline-block;
        			font-size: 1.2rem;
        			letter-spacing: normal;
        			button{
        				color: #8b8b8b;
        			}
    				&:after{
    					content:'';
    					position: absolute;
    					bottom: -2px;
    					left: 0;
    					right: auto;
    					width: auto;
    					height: 0;
    					border-top: 2px solid $azurColor;
    				}
        			&.active{
        				&:after{
        					right: 0;
        				}
        				button{
        					color:$azurColor;
        				}
        			}
        		}
        	}
        }
        .ssCarousel{
        	&:before{
      			position:absolute;
      			content:'';
      			top:182px;
      			left: -1000px;
      			right: -1000px;
      			width: auto;
            margin: auto;
      			height: 2px;
      			background-color: #c9c9c9;
      			z-index: 0;
        	}
        }
        .success-story-carousel{
          margin-left: 27%;
          margin-right: auto;
        	li{
        		position: relative;
        		z-index: 1;
            .timeline-peugeot{
	        		opacity:0.5;
	        		transition:0.5s opacity;
    				  picture{
    					 margin-bottom: 60px;
    				  }
            }
        		&:before{
        			position:absolute;
        			content:'';
        			width: 36px;
        			height: 36px;
                    border: 2px solid #c9c9c9;
                    border-radius: 18px;
                    background-color: #f0f0f0;
        			top: 145px;
        			left: 0;
        			right: 0;
                    margin: auto;
        		}
        		&:after{
        			position:absolute;
        			content:'';
        			width: 6px;
        			height: 6px;
        			border-radius:11px;
        			background-color: $azurColor;
        			top: 160px;
        			left: 0;
        			right: 0;
        			margin: auto;
        			transition:500ms;
        		}
        		&.activeSlide{
        			.timeline-peugeot{
        				opacity: 1;
        			}
	        		&:after{
	        			width: 22px;
	        			height: 22px;
	        			top:152px;
	        		}
	        	}
        	}
        	&.nocarousel{
        		margin-left: 0;
        		margin-right: 0;
        		display: flex;
        		flex-direction: row;
        		justify-content: space-around;
        		&:after{
        			content:none;
        		}
	        	li{
	        		.timeline-peugeot{
	        			opacity: 1;
	        		}
	        		&:before{
	        			content:none;
	        		}
	        		&:after{
	        			content:none;
	        		}
	        	}
        	}
        }
        .world-story-carousel{
        	margin-left: 27%;
        	margin-right: auto;
          li{
            opacity:0.5;
            transition:0.5s opacity;
            &.activeSlide{
              opacity: 1;
            }
          }
        	&.nocarousel{
        		margin-left: 0;
        		margin-right: 0;
        		display: flex;
        		flex-direction: row;
        		justify-content: space-around;
        		li{
        			opacity: 1;
        		}
        	}
          .timeline-world{
            width: 70%;
          }
        }
    }
  }
  
  [class*="section__"] {
  	.lame__content h3{
  		font-size: 1.6rem;
  		font-weight: 400;
  		margin-bottom: 0.6em;
  	}
  	p{
  		font-size: 1.2rem;
  		margin-top: 0;
  		margin-bottom: 12px;
  	}
    .bg-lame img{
      top: 50%;
      transform: translateY(-50%);
      bottom: auto;
    }
  }
	.section__moto3 .lame__content{
		h1,h2{
			width: 133px;
			height: 32px;
			margin-left: 0;
		}
	}
	.section__moto3, .section__paris-saigon{
		.bg-lame img{
			top: auto;
			bottom: 0;
			transform:translateY(0);
		}
	}
  .section__moto3-detail-3{
  	position: relative;
  	overflow: hidden;
  	.thesliderbackground img{
  		position: absolute;
  		top: 0;
  		left: 0;
  		width: 100%;
  	}
    .section__moto3-slider{
      margin:0 25%;
    }

	/* Carousel */

    .bx-wrapper {

      .bx-controls-direction a {
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        width: 25%;
        height: 100%;
        margin-top: 0;
        background-color: rgba(0,139,200,0.85);
        
        &.bx-next {
          right: 0;
          left: auto;
        }
        
        &:after {
          width: 20px;
          height: 20px;
          border-right: 1px solid #fff;
          border-bottom: 1px solid #fff;
          top: 50%;
          transform: translateY(-50%) rotate(-225deg);
          transform-origin: 50%;
          left: 0;
          right: 0;
          margin: auto;
        }
    
        &.bx-next:after{
          transform: translateY(-50%) rotate(-45deg);
        }
      }
    }
  }
  .timeline-detail {
    top: 0px;
    bottom: 0;
    height: 100%;
    left: 0;
    right: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
  
    .timeline-detail-content {
    	position: absolute;
    	left: 0;
    	right: 0;
    	top: 120px;
      padding: 30px 30px 10px;
		width: 320px;
		margin: auto;  
		h5{
			font-size: 2rem;
			font-weight: 400;
			margin-bottom: 15px;
		}
		picture img{
			margin-bottom: 20px;
		}
		p{
			font-size: 1.2rem;
			margin-bottom: 20px;
		}
		a.simplecta{
			font-size: 1.3rem;
		}
    }
  }
}
