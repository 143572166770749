// HOME

.home {
	
	.block-content .paris-saigon h1{
		width: 209px;
		height: 71px;
	}
	.block-content .moto3 h2{
		width: 150px;
		height: 37px;
	}

	.lame__content .paris-saigon h2{
		width: 200px;
		height: 185px;
	}

	.lame__content .cent-vingt-ans h2{
		width: 150px;
		height: 105px;
	}

	.section__slider{
		.lame-last-block-empty{
			.lame__content{
				min-height: 35.1vw;
				& + .lame__content {
					min-height: 35.1vw;
				}
				.bg-lame img{
					width: 100%;
				}
			}
		}
	}
	
}